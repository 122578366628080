.video-player {
	position: relative;
}

.video-player__media {
	display: block;
}

/* up-to-xsmall,max-width-559px */
@media (width < 35rem) {
	.video-player__media {
		aspect-ratio: 4 / 5;
	}
}

.video-player__video {
	border-radius: var(--border-radius-5);
	display: block;
	width: 100%;
	height: auto;
}

/* up-to-xsmall,max-width-559px */
@media (width < 35rem) {
	.video-player__video {
		object-fit: cover;
		height: 100%;
	}
}

.video-player__controls {
	display: flex;
	column-gap: var(--space-10--px);
	position: absolute;
	bottom: var(--space-10--rem);
	left: var(--space-10--px);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video-player__controls {
		bottom: var(--space-30--rem);
		left: var(--space-30--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.video-player__controls {
		bottom: var(--space-60--rem);
		left: var(--space-60--px);
	}
}

.video-player__button,
.video-player__toggle__button {
	-mrh-resets: button;
	background-color: var(--color-off-white);
	border-radius: var(--border-radius-5);
	font-family: var(--font-body-sans);
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.5;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	transition:
		background-color 256ms ease-in-out,
		color 256ms ease-in-out;
	min-height: 2.75rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.video-player__button,
	.video-player__toggle__button {
		font-size: 1rem;
	}
}

.video-player__button:hover,
.video-player__toggle__button:hover {
	background-color: var(--color-black-warm);
	color: var(--color-off-white);
}

.video-player__toggle__button {
	display: flex;
	padding-inline: var(--space-10--px);
	justify-content: center;
	align-items: center;
	width: 2.75rem;
}

.video-player__toggle__play,
.video-player__toggle__pause {
	background-color: var(--color-black-warm);
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100%;
	transition: background-color 256ms ease-in-out;
	width: 1.5rem;
	height: 1.5rem;
}

.video-player__toggle__button:hover .video-player__toggle__play,
.video-player__toggle__button:hover .video-player__toggle__pause {
	background-color: var(--color-off-white);
}

.video-player__toggle__play {
	mask-image: url("../../../images/play.svg");
}

.video-player__toggle[data-playing] .video-player__toggle__play {
	display: none;
}

.video-player__toggle__pause {
	display: none;
	mask-image: url("../../../images/pause.svg");
}

.video-player__toggle[data-playing] .video-player__toggle__pause {
	display: block;
}
