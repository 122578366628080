.navigation-overlay {
}

.navigation-overlay__header {
	display: flex;
	padding-block: var(--space-10--rem);
	justify-content: space-between;
	align-items: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.navigation-overlay__header {
		padding-block: var(--space-20--rem);
	}
}

.navigation-overlay__header__logo .icon {
	display: block;
	fill: var(--color-off-white);
	width: 10.75rem;
	height: 1.25rem;
}

/* #region Hamburger */
.navigation-overlay__header__hamburger {
	-mrh-resets: button;
	display: flex;
	flex-direction: column;
	padding-inline: 14px;
	padding-block: 0.875rem;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
}

.navigation-overlay__header__hamburger__line {
	background-color: var(--color-off-white);
	display: block;
	transition: transform 176ms ease-in-out;
	width: 0.9375rem;
	height: 0.125rem;
}

.modal-dialog[data-animation-start] .navigation-overlay__header__hamburger__line:first-child {
	transform: rotate(45deg) translate(0.5px, 1px);
	transform-origin: center;
}

.modal-dialog[data-animation-start] .navigation-overlay__header__hamburger__line:last-child {
	transform: rotate(-45deg) translate(0.5px, -1px);
	transform-origin: center;
}
/* #endregion Hamburger */

/* #region Navigation */
.navigation-overlay__primary {
	display: grid;
	list-style-type: none;
	margin-block-start: var(--space-60--rem);
	margin-block-end: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.navigation-overlay__primary {
		column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

.navigation-overlay__primary__item {
}

.navigation-overlay__primary__item__link {
	border-bottom: 1px solid var(--color-off-white-70);
	box-sizing: border-box;
	color: var(--color-off-white-70);
	display: block;
	padding-block: var(--space-10--rem);
	text-decoration: none;
	transition:
		color 256ms ease-in,
		border-color 256ms ease-in;
	height: 100%;
}

.navigation-overlay__primary__item:is(:active, :hover) .navigation-overlay__primary__item__link,
.navigation-overlay__primary__item[data-is-current] .navigation-overlay__primary__item__link {
	border-bottom: 2px solid var(--color-off-white);
	color: var(--color-off-white);
	margin-block-end: -1px;
}
/* #endregion Navigation */
