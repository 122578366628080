.anchor-links {
}

.anchor-links__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.anchor-links__list__item__link {
	border-bottom: 1px solid var(--color-black-warm-20);
	display: block;
	padding-block: var(--space-10--rem);
	text-decoration: none;
	transition: border-color 256ms ease-in;
}

.anchor-links__list__item:first-child .anchor-links__list__item__link {
	border-top: 1px solid var(--color-black-warm-20);
}

.anchor-links__list__item__link:is(:hover, :active) {
	border-bottom: 2px solid var(--color-black-warm);
	margin-block-end: -1px;
}
