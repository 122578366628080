.job-list {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.job-list {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.job-list {
		grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
	}
}

/* from-xlarge,min-width-1680px */
@media (width >= 105rem) {
	.job-list {
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}
