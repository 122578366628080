.job-item {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	row-gap: var(--space-60--rem);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	justify-content: space-between;
	text-decoration: none;
	transition: background-color 256ms ease-out;
	height: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.job-item {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.job-item:any-link:hover {
	background-color: var(--color-black-warm-10);
}

.job-item__title {
	text-decoration: underline;
	text-decoration-color: var(--color-black-warm-20);
	transition: text-decoration-color 256ms ease-out;
}

.job-item:any-link:hover .job-item__title {
	text-decoration-color: var(--color-black-warm);
}

.job-item__excerpt {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	margin-block-end: var(--space-20--rem);
	overflow: hidden;
}

/* from-xsmall,min-width-560px */
@media (35rem <= width < 48rem) or (width >= 64rem) {
	.job-item__data__meta {
		display: flex;
		flex-wrap: wrap;
		row-gap: var(--space-15--rem);
		column-gap: var(--space-15--px);
	}
}

.job-item__data__meta {
	margin-block-start: var(--space-20--rem);
}

.job-item__team-and-duration,
.job-item__location {
	display: flex;
	column-gap: 5px;
}

/* up-to-xsmall,max-width-559px */
@media (width < 35rem) or (48rem <= width < 64rem) {
	.job-item__team-and-duration ~ .job-item__location {
		margin-block-start: var(--space-15--rem);
	}
}

.job-item__team-and-duration::before,
.job-item__location::before {
	background-color: var(--color-black-warm);
	content: "";
	display: block;
	flex-shrink: 0;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100%;
	width: 1.5rem;
	height: 1.5rem;
}

.job-item__team-and-duration::before {
	mask-image: url("../../images/work_outline.svg");
}

.job-item__location::before {
	mask-image: url("../../images/place.svg");
}

.job-item__cta {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	display: flex;
	column-gap: var(--space-10--px);
	margin-block-start: auto;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	justify-content: space-between;
	transition:
		background-color 256ms ease-out,
		color 256ms ease-out;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.job-item__cta {
		width: max-content;
	}
}

.job-item__cta::after {
	background-color: var(--color-black-warm);
	content: "";
	display: block;
	flex-shrink: 0;
	mask-image: url("../../images/launch.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100%;
	transition: background-color 256ms ease-out;
	width: 1.5rem;
	height: 1.5rem;
}

.job-item:hover .job-item__cta {
	background-color: var(--color-black-warm);
	color: var(--color-off-white);
}

.job-item:hover .job-item__cta::after {
	background-color: var(--color-off-white);
}
