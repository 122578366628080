.pages-list {
}

.pages-list__title {
	margin-block-end: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.pages-list__title {
		width: calc(50% - var(--space-15--px));
	}
}

.pages-list__list {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.pages-list__list {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.pages-list__page-item[data-is-highlighted] {
		grid-column: span 2;
	}
}

.page-item {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: grid;
	grid-row-gap: var(--space-30--rem);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	text-decoration: none;
	transition: background-color 256ms ease-out;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
		height: 100%;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.page-item {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.page-item:hover {
	background-color: var(--color-black-warm-10);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item__image-container {
		aspect-ratio: 3 / 2;
		display: block;
	}
}

.page-item__image {
	width: 100%;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item__image {
		object-fit: contain;
		object-position: left;
		width: 100%;
		height: 100%;
	}
}

.page-item__image-placeholder {
	display: none;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item__image-placeholder {
		aspect-ratio: 3 / 2;
		background-color: var(--color-black-warm-5);
		display: block;
	}

	.page-item__image-placeholder img {
		opacity: 0.05;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item__data {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.page-item__data__title {
	text-decoration: underline;
	text-decoration-color: var(--color-black-warm-20);
	transition: text-decoration-color 256ms ease-out;
}

.page-item:hover .page-item__data__title {
	text-decoration-color: var(--color-black-warm);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.page-item__data__title {
		display: -webkit-box;
		padding-block-end: 0.25rem; /* Prevent overflow hidden from hiding text underline */
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.page-item__data__title {
		-webkit-line-clamp: 4;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.page-item__data__title {
		-webkit-line-clamp: 3;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	@supports (-webkit-line-clamp: 4) {
		.page-item__data__meta p {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			display: -webkit-box;
			overflow: hidden;
		}
	}
}
