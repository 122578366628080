[data-animated-icon="responsibility"] {
	color: var(--color-black-warm);
}

[data-animated-icon="responsibility"] [data-shape~="a"] {
	fill: none;
	stroke: currentColor;
	stroke-miterlimit: 10;
	stroke-width: 20px;
	transform-origin: center;
}

[data-animated-icon="responsibility"] [data-shape~="b"] {
	fill: currentColor;
}

[data-animated-icon="responsibility"] [data-shape~="a"] {
	&:is([data-shape~="0"]) {
		transform: rotate(calc(0 * 15deg));
	}

	&:is([data-shape~="1"]) {
		transform: rotate(calc(1 * 15deg));
	}

	&:is([data-shape~="2"]) {
		transform: rotate(calc(2 * 15deg));
	}

	&:is([data-shape~="3"]) {
		transform: rotate(calc(3 * 15deg));
	}

	&:is([data-shape~="4"]) {
		transform: rotate(calc(4 * 15deg));
	}

	&:is([data-shape~="5"]) {
		transform: rotate(calc(5 * 15deg));
	}

	&:is([data-shape~="6"]) {
		transform: rotate(calc(6 * 15deg));
	}

	&:is([data-shape~="7"]) {
		transform: rotate(calc(7 * 15deg));
	}

	&:is([data-shape~="8"]) {
		transform: rotate(calc(8 * 15deg));
	}

	&:is([data-shape~="9"]) {
		transform: rotate(calc(9 * 15deg));
	}

	&:is([data-shape~="10"]) {
		transform: rotate(calc(10 * 15deg));
	}

	&:is([data-shape~="11"]) {
		transform: rotate(calc(11 * 15deg));
	}
}

[data-animated-icon="responsibility"] [data-group] {
	animation-duration: 3s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

[data-animated-icon="responsibility"] [data-group="a"] {
	transform: rotate(0);
	transform-origin: center;
}

[data-animated-icon="responsibility"] [data-group="b"] {
	transform: rotate(0);
	transform-origin: center;
}

[data-hover] [data-animated-icon="responsibility"] [data-group="b"] {
	animation-name: ANIMATED_ICON__RESPONSIBILITY__GROUP_B;
}

@keyframes ANIMATED_ICON__RESPONSIBILITY__GROUP_B {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}
