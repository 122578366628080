[data-animated-icon="freedom"] {
	color: var(--color-black-warm);
}

[data-animated-icon="freedom"] [data-shape~="a"] {
	fill: none;
	stroke: currentColor;
	stroke-width: 20px;
}

[data-animated-icon="freedom"] [data-shape~="b"] {
	fill: currentColor;
}

[data-animated-icon="freedom"] [data-shape] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
	transform-origin: center;
}

[data-hover] [data-animated-icon="freedom"] [data-shape~="1"] {
	animation-name: ANIMATED_ICON__freedom__SHAPE_1;
}

[data-hover] [data-animated-icon="freedom"] [data-shape~="2"] {
	animation-name: ANIMATED_ICON__freedom__SHAPE_2;
}

[data-hover] [data-animated-icon="freedom"] [data-shape~="3"] {
	animation-name: ANIMATED_ICON__freedom__SHAPE_3;
}

[data-hover] [data-animated-icon="freedom"] [data-shape~="4"] {
	animation-name: ANIMATED_ICON__freedom__SHAPE_4;
}

@keyframes ANIMATED_ICON__freedom__SHAPE_1 {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(0, 410px);
	}
}

@keyframes ANIMATED_ICON__freedom__SHAPE_2 {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(0, -410px);
	}
}

@keyframes ANIMATED_ICON__freedom__SHAPE_3 {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(410px, 0);
	}
}

@keyframes ANIMATED_ICON__freedom__SHAPE_4 {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(-410px, 0);
	}
}
