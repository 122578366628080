.spotlight {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.spotlight {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.spotlight__column[data-column-a] .spotlight__column__media {
		width: 50%;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.spotlight__column[data-column-b] .image {
		display: block;
		object-fit: cover;
		height: 100%;
	}
}

.spotlight__column[data-column-a] {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-60--rem);
	justify-content: space-between;
}

.spotlight__column__inner {
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.spotlight__column__inner {
		margin-inline-end: var(--space-100--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.spotlight__column__inner {
		margin-inline-end: var(--space-150--px);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.spotlight__column__inner {
		margin-inline-end: calc(var(--space-100--px) * 2);
	}
}

.spotlight__column__inner__link {
	color: var(--color-black-warm-70);
	display: inline-flex;
	align-items: baseline;
	text-transform: uppercase;
	transition:
		color 256ms ease-in-out,
		text-decoration-color 256ms ease-in-out;
}

.spotlight__column__inner__link:hover {
	color: var(--color-black-warm);
	text-decoration-color: var(--color-black-warm);
}

.spotlight__column__inner__link::before {
	background-color: var(--color-black-warm-70);
	content: "";
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-15--px);
	mask-image: url("../../../images/arrow_forward.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100%;
	transition: background-color 256ms ease-in-out;
	width: 0.875rem;
	height: 0.75rem;
}

.spotlight__column__inner__link:hover::before {
	background-color: var(--color-black-warm);
}
