.hero {
	background-color: var(--theme-background-color);
	padding-block-start: var(--space-10--rem);
	padding-block-end: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero {
		padding-block-start: var(--space-20--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero {
		padding-block-start: var(--space-30--rem);
	}
}

.hero__title {
	margin-block: var(--space-100--rem);
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero__title {
		margin-block: var(--space-150--rem);
		padding-inline: var(--space-100--px);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.hero__title {
		margin-block: var(--space-200--rem);
		padding-inline: var(--space-200--px);
	}
}

.hero__subtitle {
	text-align: center;
	text-wrap: balance;
}

/* from-xlarge,min-width-1680px */
@media (width >= 105rem) {
	.hero__subtitle {
		padding-inline: var(--space-100--px);
	}
}
