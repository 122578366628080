[data-animated-icon="network"] {
	color: var(--color-black-warm);
	overflow: hidden;
}

[data-animated-icon="network"] [data-shape="a"] {
	fill: black;
}

[data-animated-icon="network"] [data-shape="b"] {
	fill: currentColor;
}

[data-animated-icon="network"] [data-group] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
	transform-origin: center;
}

[data-hover] [data-animated-icon="network"] [data-group="a"] {
	animation-name: ANIMATED_ICON__NETWORK__GROUP_A;
}

[data-hover] [data-animated-icon="network"] [data-group="b"] {
	animation-name: ANIMATED_ICON__NETWORK__GROUP_B;
}

@keyframes ANIMATED_ICON__NETWORK__GROUP_A {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(120deg);
	}
}

@keyframes ANIMATED_ICON__NETWORK__GROUP_B {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(-120deg);
	}
}
