.service-topic-50-50 {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.service-topic-50-50 {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.service-topic-50-50__column[data-column-right] {
		grid-column: 2;
	}
}

.service-topic-50-50__column__title {
	margin: 0;
}

.service-topic-50-50__column__title__number {
	color: var(--color-black-warm-70);
}

.service-topic-50-50__column__row:not(:first-child) {
	margin-block-start: var(--space-60--rem);
}
