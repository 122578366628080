.l-error {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-30--rem);
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
	min-height: 100svh;
}

.l-error__header {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-30--rem);
	align-items: center;
}

.l-error__header__logo {
	display: block;
	width: 10.75rem;
	height: 1.25rem;
}

.l-error__title {
	margin: 0;
}
