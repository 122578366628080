[data-animated-icon="flexibility"] {
	color: var(--color-black-warm);
}

[data-animated-icon="flexibility"] [data-shape="a"] {
	fill: currentColor;
}

[data-animated-icon="flexibility"] [data-group],
[data-animated-icon="flexibility"] [data-shape] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

[data-animated-icon="flexibility"] [data-group="a"] {
	transform: rotate(45deg);
	transform-origin: center;
}

[data-animated-icon="flexibility"] [data-group="b"] {
	transform: scale(1.08);
	transform-origin: center;
}

[data-animated-icon="flexibility"] [data-shape="b"] {
	transform: rotate(45deg) scale(1);
	transform-origin: center;
}

[data-hover] [data-animated-icon="flexibility"] [data-group="a"] {
	animation-name: ANIMATED_ICON__flexibility__GROUP_A;
}

[data-hover] [data-animated-icon="flexibility"] [data-group="b"] {
	animation-name: ANIMATED_ICON__flexibility__GROUP_B;
}

[data-hover] [data-animated-icon="flexibility"] [data-shape="b"] {
	animation-name: ANIMATED_ICON__flexibility__SHAPE_B;
}

@keyframes ANIMATED_ICON__flexibility__GROUP_A {
	from {
		transform: rotate(45deg);
	}

	to {
		transform: rotate(calc(45deg + 90deg));
	}
}

@keyframes ANIMATED_ICON__flexibility__GROUP_B {
	from,
	to {
		transform: scale(1.08);
	}

	50% {
		transform: scale(1);
	}
}

@keyframes ANIMATED_ICON__flexibility__SHAPE_B {
	from,
	to {
		transform: rotate(45deg) scale(1);
	}

	50% {
		transform: rotate(45deg) scale(1.5);
	}
}
