.video-overlay {
	background-color: var(--color-black-warm);
	position: relative;
	width: 100%;
	height: 100%;
}

.video-overlay__media {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-inline: var(--space-20--px);
	padding-block: 6.75rem;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
	max-height: 100%;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.video-overlay__media {
		padding-inline: 112px;
		padding-block: 7.5625rem;
	}
}

.video-overlay__media__player {
	aspect-ratio: 16 / 9;
	display: block;
	margin: 0 auto;
	position: relative;
	max-height: 100%;
	z-index: 2;
}

.video-overlay[data-orientation="portrait"] .video-overlay__media__player {
	aspect-ratio: 9 / 16;
}

.video-overlay__media__source {
	opacity: 0;
	transition: opacity 256ms ease-in-out;
	visibility: hidden;
	width: 100%;
	height: auto;
}

.video-overlay__media__source[data-playback-started] {
	opacity: 1;
	visibility: visible;
}

.video-overlay__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.video-overlay__close-button {
	-mrh-resets: button;
	background-color: var(--color-off-white);
	border-radius: var(--border-radius-5);
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: var(--space-20--rem);
	right: var(--space-20--px);
	transition: background-color 256ms ease-out;
	width: 2.75rem;
	height: 2.75rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video-overlay__close-button {
		top: var(--space-50--rem);
		right: var(--space-50--px);
	}
}

.video-overlay__close-button:hover {
	background-color: var(--color-black-warm);
}

.video-overlay__close-button::after {
	background-color: var(--color-black-warm);
	content: "";
	display: block;
	mask-image: url("../../images/close.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	transition: background-color 256ms ease-out;
	width: 1.5rem;
	height: 1.5rem;
}

.video-overlay__close-button:hover::after {
	background-color: var(--color-off-white);
}
