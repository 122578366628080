[data-animated-icon="partnerships"] {
	color: var(--color-black-warm);
}

[data-animated-icon="partnerships"] [data-shape="a"],
[data-animated-icon="partnerships"] [data-shape="b"] {
	fill: currentColor;
}

[data-animated-icon="partnerships"] [data-shape="c"] {
	fill: transparent;
	stroke: currentColor;
	stroke-width: 20px;
}

[data-animated-icon="partnerships"] [data-group] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

[data-animated-icon="partnerships"] [data-group="a"] {
	transform: rotate(45deg);
	transform-origin: center;
}

[data-hover] [data-animated-icon="partnerships"] [data-group="a"] {
	animation-name: ANIMATED_ICON__PARTNERSHIPS__GROUP_A;
}

@keyframes ANIMATED_ICON__PARTNERSHIPS__GROUP_A {
	from {
		transform: rotate(45deg);
	}

	to {
		transform: rotate(calc(45deg + 360deg));
	}
}
