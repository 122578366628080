.job-list-latest {
}

.job-list-latest__title {
	margin-block-end: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.job-list-latest__title {
		width: calc(50% - var(--space-15--px));
	}
}

/* up-to-small,max-width-767px */
@media (width < 48rem) or (80rem <= width < 105rem) {
	.job-list-latest .job-list__item:nth-child(3) {
		display: none;
	}
}

.job-list-latest__cta {
	border: 1px solid var(--color-black-warm-20);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: block;
	padding-inline: var(--space-20--px);
	padding-block-start: var(--space-20--rem);
	text-decoration-color: var(--color-black-warm-20);
	text-decoration-thickness: 0.125rem;
	height: 100%;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.job-list-latest__cta {
		padding-block-end: var(--space-80--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.job-list-latest__cta {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}
