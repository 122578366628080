.metadata {
}

.metadata__title {
	margin-bottom: var(--space-40--rem);
	text-align: center;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.metadata__title {
		margin-bottom: var(--space-60--rem);
	}
}

.metadata__list {
	display: grid;
	row-gap: var(--space-20--rem);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.metadata__list {
		display: grid;
		row-gap: var(--space-30--rem);
		column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.metadata__list {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

.metadata__list-item {
	background-color: var(--color-black-warm-5);
	border-radius: var(--space-5--px);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.metadata__list-item {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.list-item__content__list {
	list-style-type: disc;
	padding-inline-start: var(--space-20--px);
}

.metadata__list-item__title {
	margin-bottom: var(--space-20--rem);
}

.metadata__list-item__content ul li:not([class]):not(:last-child) {
	padding-bottom: 0;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.metadata__list-item__title {
		margin-bottom: var(--space-30--rem);
	}
}
