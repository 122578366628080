.article-item-highlighted {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: grid;
	grid-row-gap: var(--space-30--rem);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	text-decoration: none;
	transition: background-color 256ms ease-out;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-item-highlighted {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
		height: 100%;
	}
}

.article-item-highlighted:hover {
	background-color: var(--color-black-warm-10);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-item-highlighted__image-container {
		aspect-ratio: 3 / 2;
		display: block;
	}
}

.article-item-highlighted__image {
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-item-highlighted__image {
		object-fit: contain;
		object-position: right;
		width: 100%;
		height: 100%;
	}
}

.article-item-highlighted__image-placeholder {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-item-highlighted__image-placeholder {
		aspect-ratio: 3 / 2;
		background-color: var(--color-black-warm-5);
		display: block;
	}

	.article-item-highlighted__image-placeholder img {
		opacity: 0.05;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.article-item-highlighted__data {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.article-item-highlighted__data__title {
	text-decoration: underline;
	text-decoration-color: var(--color-black-warm-20);
	text-decoration-thickness: 0.125rem;
	transition: text-decoration-color 256ms ease-out;
}

.article-item-highlighted:hover .article-item-highlighted__data__title {
	text-decoration-color: var(--color-black-warm);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-item-highlighted__data__title {
		display: -webkit-box;
		padding-block-end: 0.25rem; /* Prevent overflow hidden from hiding text underline */
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.article-item-highlighted__data__title {
		-webkit-line-clamp: 4;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.article-item-highlighted__data__title {
		-webkit-line-clamp: 5;
	}
}
