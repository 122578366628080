.doormat {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	overflow: hidden;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.doormat {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

.doormat__header {
	background-color: var(--color-black-warm);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: var(--color-off-white);
	padding-inline: var(--space-20--px);
	padding-block-start: var(--space-20--rem);
	position: relative;
}

/* up-to-medium,max-width-1023px */
@media (width < 64rem) {
	.doormat__header {
		padding-block-end: 12.5rem;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.doormat__header {
		padding-inline: var(--space-30--px);
		padding-block-start: var(--space-30--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.doormat__header {
		border-top-right-radius: 0;
		border-bottom-left-radius: 10px;
		padding-inline: var(--space-60--px);
		padding-block: var(--space-60--rem);
	}
}

.doormat__header__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.doormat__header__background__video {
	border-top-left-radius: var(--border-radius-5);
	border-top-right-radius: var(--border-radius-5);
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.doormat__header__background__video {
		border-top-right-radius: 0;
		border-bottom-left-radius: var(--border-radius-5);
	}
}

.doormat__header__title,
.doormat__header__subtitle {
	margin-block: 0;
	position: relative;
}

.doormat__header__subtitle {
	color: var(--color-off-white-70);
}

.doormat__blocks {
	display: grid;
	grid-row-gap: var(--space-60--rem);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.doormat__blocks {
		padding-inline: var(--space-30--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.doormat__blocks {
		padding-inline: var(--space-60--px);
	}
}

.doormat__blocks__item:not(:last-child) {
	border-bottom: 1px solid var(--color-black-warm-20);
	padding-block-end: var(--space-60--rem);
}

.doormat__blocks__item__title {
	margin-block-end: var(--space-60--rem);
}

.doormat__blocks__item__link {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	display: inline-block;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	text-decoration: none;
	transition:
		color 256ms ease-out,
		background-color 256ms ease-out;
}

.doormat__blocks__item__link:hover {
	background-color: var(--color-black-warm);
	color: var(--color-off-white);
}
