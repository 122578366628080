.teamlisting {
}

.teamlisting__text {
	margin-block-end: var(--space-30--rem);
}

.teamlisting__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-10--px);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.teamlisting__list-item {
	border: 1px solid var(--color-black-warm-20);
	border-radius: var(--space-5--px);
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	width: fit-content;
}

.teamlisting__list-item__title {
	display: flex;
	padding-inline-start: var(--space-30--px);
	justify-content: center;
	align-items: center;
	position: relative;
}

@supports (-webkit-line-clamp: 1) {
	.teamlisting__list-item__title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		display: flex;
		display: -webkit-box;
		overflow: hidden;
	}
}

.teamlisting__list-item__title::before {
	background-color: var(--color-black-warm-70);
	content: "";
	display: block;
	mask-image: url("../../../images/user.svg");
	mask-position: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	transition: background-color 256ms linear;
	width: 16px;
	height: 16px;
}
