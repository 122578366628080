[data-animated-icon="remote-talent"] {
	color: var(--color-black-warm);
}

[data-animated-icon="remote-talent"] [data-shape="a"] {
	fill: currentColor;
}

[data-animated-icon="remote-talent"] [data-line="a"] {
	fill: none;
	stroke: currentColor;
	stroke-miterlimit: 10;
	stroke-width: 20px;
}

[data-animated-icon="remote-talent"] [data-group] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

[data-hover] [data-animated-icon="remote-talent"] [data-group="a"] {
	animation-name: ANIMATED_ICON__REMOTE_TALENT__GROUP_A;
}

[data-hover] [data-animated-icon="remote-talent"] [data-group="b"] {
	animation-name: ANIMATED_ICON__REMOTE_TALENT__GROUP_B;
}

[data-hover] [data-animated-icon="remote-talent"] [data-group="c"] {
	animation-name: ANIMATED_ICON__REMOTE_TALENT__GROUP_C;
}

[data-hover] [data-animated-icon="remote-talent"] [data-group="d"] {
	animation-name: ANIMATED_ICON__REMOTE_TALENT__GROUP_D;
}

@keyframes ANIMATED_ICON__REMOTE_TALENT__GROUP_A {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc(-1 * ((1080px / 2) - 190px)), calc((1080px / 2) - 190px));
	}
}

@keyframes ANIMATED_ICON__REMOTE_TALENT__GROUP_B {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc((1080px / 2) - 190px), calc((1080px / 2) - 190px));
	}
}

@keyframes ANIMATED_ICON__REMOTE_TALENT__GROUP_C {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc(-1 * ((1080px / 2) - 190px)), calc(-1 * ((1080px / 2) - 190px)));
	}
}

@keyframes ANIMATED_ICON__REMOTE_TALENT__GROUP_D {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc((1080px / 2) - 190px), calc(-1 * ((1080px / 2) - 190px)));
	}
}
