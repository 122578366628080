.l-archive {
}

.l-archive__filter {
	margin-block-end: var(--space-60--rem);
}

.l-archive__pagination {
	margin-block-start: var(--space-60--rem);
}
