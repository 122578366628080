.accordion {
	color: var(--color-black-warm-70);
	margin: 0;
	padding: 0;
	width: 100%;
}

.accordion__intro-title {
	color: var(--color-black-warm);
	margin-bottom: var(--space-20--rem);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion__intro-title {
		margin-bottom: var(--space-30--rem);
	}
}

.accordion__item {
	border-bottom: 1px solid var(--color-black-warm-20);
	transition: border-color 256ms ease-in;
}

.accordion__item:hover {
	border-bottom: 2px solid var(--color-black-warm);
	margin-block-end: -1px;
}

.accordion__item:has([aria-expanded="true"]) {
	border-bottom: 2px solid var(--color-black-warm);
	margin-block-end: -1px;
}

.accordion__list-item {
	border-block-end: var(--space-1--rem) solid var(--color-black-warm-10);
	padding-block: var(--space-5--rem);
}

.accordion__heading {
	margin: 0;
	padding: 0;
}

.accordion__trigger {
	background: none;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	outline: none;
	padding-inline: 0;
	padding-block: var(--space-5--rem);
	position: relative;
	text-align: left;
	width: 100%;
}

.accordion__trigger[aria-expanded="true"] {
	color: var(--color-black-warm);
}

html:not(.no-js) .accordion__trigger:focus,
html:not(.no-js) .accordion__trigger:hover {
	color: var(--color-black-warm);
	cursor: pointer;
}

.accordion__title {
	display: block;
	font-size: 1.25rem;
	outline: none;
	padding-inline-end: var(--space-32--px);
	pointer-events: none;
	position: relative;
}

html.no-js .accordion__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}

.accordion__title__icon {
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 1.5rem;
	height: 1.5rem;
}

.accordion__title__icon::after {
	background-color: var(--color-black-warm-70);
	content: "";
	display: block;
	mask-image: url("../../images/add.svg");
	mask-position: center;
	transition: background-color 256ms linear;
	width: 1.5rem;
	height: 1.5rem;
}

.accordion__item:hover .accordion__title__icon::after {
	background-color: var(--color-black-warm);
}

.accordion__trigger[aria-expanded="true"] .accordion__title__icon::after {
	background-color: var(--color-black-warm);
	mask-image: url("../../images/close-1.svg");
}

html.no-js .accordion__title__icon {
	display: none;
}

.accordion__panel {
	padding-block-start: var(--space-30--rem);
	padding-block-end: var(--space-60--rem);
}

.accordion__panel:not([hidden="until-found"]) {
	border-block-start: var(--space-1--rem) solid var(--color-black-warm);
	border-top: 2px solid var(--color-black-warm);
	color: var(--color-black-warm);
}

html:not(.no-js, .js-loading-error) .accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
