.row {
	margin-block: var(--space-60--rem);
	position: relative;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.row {
		margin-block: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.row {
		margin-block: var(--space-100--rem);
	}
}
