.blocks-with-anchor-links {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.blocks-with-anchor-links {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: minmax(0, calc(100% / 3)) 1px auto;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.blocks-with-anchor-links {
		grid-column-gap: var(--space-60--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.blocks-with-anchor-links {
		margin: 0 auto;
		max-width: 90rem;
	}
}

/* #region Anchors */
.blocks-with-anchor-links__anchors {
	padding-inline: var(--space-20--px);
}

/* from-xsmall,min-width-560px and up-to-small,max-width-767px */
@media (width >= 35rem) and (width < 48rem) {
	.blocks-with-anchor-links__anchors {
		width: 50%;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.blocks-with-anchor-links__anchors {
		padding-inline-start: var(--space-50--px);
		padding-inline-end: 0;
	}
}

/* from-xlarge,min-width-1680px */
@media (width >= 105rem) {
	.blocks-with-anchor-links__anchors {
		padding-inline-start: 0;
	}
}
/* #endregion Anchors */

/* #region Separator */
@media (width < 48rem) {
	.blocks-with-anchor-links__separator {
		display: none;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.blocks-with-anchor-links__separator {
		background-color: var(--color-black-warm-20);
		width: 1px;
		height: 100%;
	}
}
/* #endregion Separator */

/* #region Content */
.blocks-with-anchor-links__content .row:first-child {
	margin-block-start: 0;
}

.blocks-with-anchor-links__content .row:last-child {
	margin-block-end: 0;
}

.blocks-with-anchor-links__content .wrapper {
	margin-inline: 0;
	padding-inline-start: 0;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.blocks-with-anchor-links__content {
		padding-inline: var(--space-20--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.blocks-with-anchor-links__content {
		padding-inline-end: var(--space-50--px);
	}
}

/* from-xlarge,min-width-1680px */
@media (width >= 105rem) {
	.blocks-with-anchor-links__content {
		padding-inline-end: 0;
	}
}
/* #endregion Content */
