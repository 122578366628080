body {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-30--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

table caption {
	margin-block-start: var(--space-15--rem);
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-15--rem);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0;
	margin-block-end: var(--space-30--rem);
	padding-inline-start: 1.25rem;
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
	padding-block-start: 0.25rem;
}

li:not([class]) {
	margin: 0;
	position: relative;
}

li:not([class]):not(:last-child) {
	padding: 0 0 0.25rem 0;
}

/* #region Shared link styles (WYSIWYG and components) */
:any-link {
	color: currentColor;
	text-decoration: underline;
	text-decoration-color: var(--color-black-warm-50);
	text-decoration-thickness: 0.0625rem;
	text-underline-offset: 0.125rem;
	transition: text-decoration-color 256ms ease-in-out;
}

:any-link:hover {
	text-decoration-color: var(--color-black-warm);
}

/* #endregion Shared link styles (WYSIWYG and components) */

/* #region WYSIWYG link styles */
:any-link:not([class]) {
	display: inline;
}

p a:not([class]) {
	line-height: inherit;
}
/* #endregion WYSIWYG link styles */

.mr-table-scroll-container {
	overflow-x: auto;
}

mr-hover-animation {
	display: flex;
	min-width: 0;
}
