[data-animated-icon="self-development"] {
	color: var(--color-black-warm);
}

[data-animated-icon="self-development"] [data-shape~="a"],
[data-animated-icon="self-development"] [data-shape~="b"] {
	fill: currentColor;
	transform-origin: center;
}

[data-animated-icon="self-development"] mask [data-shape~="a"],
[data-animated-icon="self-development"] mask [data-shape~="b"] {
	fill: black;
}

[data-animated-icon="self-development"] [data-shape~="a"] {
	&:is([data-shape~="0"]) {
		transform: rotate(calc(0 * 90deg));
	}

	&:is([data-shape~="1"]) {
		transform: rotate(calc(1 * 90deg));
	}

	&:is([data-shape~="2"]) {
		transform: rotate(calc(2 * 90deg));
	}

	&:is([data-shape~="3"]) {
		transform: rotate(calc(3 * 90deg));
	}
}

[data-animated-icon="self-development"] [data-shape~="b"] {
	&:is([data-shape~="0"]) {
		transform: rotate(calc(45deg + (0 * 90deg)));
	}

	&:is([data-shape~="1"]) {
		transform: rotate(calc(45deg + (1 * 90deg)));
	}

	&:is([data-shape~="2"]) {
		transform: rotate(calc(45deg + (2 * 90deg)));
	}

	&:is([data-shape~="3"]) {
		transform: rotate(calc(45deg + (3 * 90deg)));
	}
}

[data-animated-icon="self-development"] [data-group] {
	animation-duration: 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
	transform-origin: center;
}

[data-hover] [data-animated-icon="self-development"] [data-group="a"] {
	animation-name: ANIMATED_ICON__SELF_DEVELOPMENT__GROUP_A;
}

@keyframes ANIMATED_ICON__SELF_DEVELOPMENT__GROUP_A {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(90deg);
	}
}
