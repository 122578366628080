.modal-dialog {
	display: none;
	opacity: 0;
	pointer-events: none;
	position: relative;
}

.l-styleguide ~ .modal-dialog {
	z-index: 9999;
}

.modal-dialog[data-state="opening"],
.modal-dialog[data-state="open"],
.modal-dialog[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.modal-dialog:focus {
	outline: none;
}

.modal-dialog__content {
	display: block;
	opacity: 0;
	overflow-y: scroll;
}

.modal-dialog[data-state="opening"] .modal-dialog__content,
.modal-dialog[data-state="open"] .modal-dialog__content,
.modal-dialog[data-state="closing"] .modal-dialog__content {
	background-color: var(--color-black-warm);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 101;
}

.modal-dialog[data-state="open"] .modal-dialog__content {
	display: block;
	opacity: 1;
}
