.text-50-50 {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-50-50 {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-50-50__column[data-column-right] {
		grid-column: 2;
	}
}

.text-50-50__item:not(:last-child) {
	border-bottom: 1px solid var(--color-black-warm-20);
	margin-block-end: var(--space-60--rem);
	padding-block-end: var(--space-60--rem);
}
