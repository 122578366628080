.article-list {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.article-list {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.article-list__item[data-is-highlighted] {
		grid-column: span 2;
	}
}
