.media-banner {
	background-color: var(--color-black-warm);
	border-radius: var(--border-radius-5);
	color: var(--color-off-white-70);
	display: block;
	position: relative;
	text-decoration: none;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.media-banner {
		aspect-ratio: 16 / 9;
	}
}

.media-banner__video-container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.media-banner__video {
	border-radius: var(--border-radius-5);
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.media-banner :is(h2, h3) {
	margin: 0;
}

.media-banner h2 {
	color: var(--color-off-white);
}

.media-banner__content {
	box-sizing: border-box;
	padding-inline: var(--space-20--px);
	padding-block-start: var(--space-20--rem);
	position: relative;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.media-banner__content {
		padding-block-end: 12.5rem;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.media-banner__content {
		padding-inline-start: var(--space-30--px);
		padding-inline-end: 0;
		padding-block: var(--space-30--rem);
		width: 50%;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.media-banner__content {
		padding-inline-start: var(--space-60--px);
		padding-block: var(--space-60--rem);
	}
}

.media-banner__cta {
	display: flex;
	column-gap: var(--space-15--px);
	margin-block-start: var(--space-30--rem);
	align-items: baseline;
	text-decoration: underline;
	transition: color 256ms ease-in-out;
}

.media-banner__cta::before {
	background-color: currentColor;
	content: "";
	flex-shrink: 0;
	mask-image: url("../../../images/arrow_forward.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: 100%;
	transition: background-color 256ms ease-in-out;
	width: 0.875rem;
	height: 0.75rem;
}

.media-banner:hover .media-banner__cta {
	color: var(--color-off-white);
}

.media-banner:hover .media-banner__cta::before {
	background-color: var(--color-off-white);
}
