.quote {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: var(--space-30--rem);
	margin: 0;
	padding-block: var(--space-20--rem);
	justify-content: center;
	min-height: 100vh;
	min-height: 100svh;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.quote {
		padding-block: var(--space-50--rem);
	}
}

.quote__credit::before {
	content: "— ";
}
