.filter {
}

.filter__button {
	-mrh-resets: button;
	background-color: var(--color-black-warm-5);
	border: none;
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: flex;
	font-family: var(--font-body-sans);
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.5;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	justify-content: space-between;
	align-items: center;
	transition:
		background-color 256ms ease-out,
		color 256ms ease-out;
	min-width: 9.0625rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.filter__button {
		font-size: 1rem;
	}
}

.filter__button:hover,
.filter__button[aria-expanded="true"] {
	background-color: var(--color-black-warm);
	color: var(--color-off-white);
}

.filter__button span[data-hide-filter] {
	display: none;
}

.filter__button[aria-expanded="true"] span[data-hide-filter] {
	display: block;
}

.filter__button[aria-expanded="true"] span[data-show-filter] {
	display: none;
}

.filter__button__icon {
	background-color: var(--color-black-warm);
	display: block;
	mask-image: url("../../../images/filter_list.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	transition: background-color 256ms ease-out;
	width: 1.5rem;
	height: 1.5rem;
}

.filter__button:hover .filter__button__icon,
.filter__button[aria-expanded="true"] .filter__button__icon {
	background-color: var(--color-off-white);
}

.filter__list {
	border: 1px solid var(--color-black-warm-20);
	border-radius: var(--border-radius-5);
	display: none;
	flex-wrap: wrap;
	row-gap: var(--space-30--rem);
	column-gap: var(--space-30--px);
	list-style: none;
	margin-block-start: var(--space-10--rem);
	margin-block-end: 0;
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);
}

.filter__list.mr-menu--expanded {
	display: inline-flex;
}

.filter__list__link {
	color: var(--color-black-warm-70);
	text-decoration: none;
	transition: color 256ms ease-out;
}

.filter__list__link:hover,
.filter__list__item[data-is-active] .filter__list__link {
	color: var(--color-black-warm);
	text-decoration: underline;
}
