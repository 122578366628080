.primary-navigation {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.primary-navigation__item__link {
	border-bottom: 1px solid var(--color-black-warm-20);
	box-sizing: border-box;
	display: block;
	padding-block: var(--space-10--rem);
	text-decoration: none;
	transition: border-color 256ms ease-in;
	height: 100%;
}

.primary-navigation__item__link:is(:hover, :active),
.primary-navigation__item[data-is-current] .primary-navigation__item__link {
	border-bottom: 2px solid var(--color-black-warm);
	margin-bottom: -1px;
}
