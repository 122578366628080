:root {
	--theme-background-color: transparent;
	--theme-cta-banner-hover-color: var(--color-black-warm-5);
}

[data-color-theme] {
	--theme-cta-banner-hover-color: var(--theme-background-color);
}

[data-color-theme="sidecar"] {
	--theme-background-color: var(--color-sidecar);
}

[data-color-theme="quicksand"] {
	--theme-background-color: var(--color-quicksand);
}

[data-color-theme="chablis"] {
	--theme-background-color: var(--color-chablis);
}

[data-color-theme="kournikova"] {
	--theme-background-color: var(--color-kournikova);
}

[data-color-theme="texas-rose"] {
	--theme-background-color: var(--color-texas-rose);
}

[data-color-theme="swans-down"] {
	--theme-background-color: var(--color-swans-down);
}

[data-color-theme="gainsboro"] {
	--theme-background-color: var(--color-gainsboro);
}

[data-color-theme="heather"] {
	--theme-background-color: var(--color-heather);
}

[data-color-theme="onahou"] {
	--theme-background-color: var(--color-onahou);
}

[data-color-theme="pale-cornflower-blue"] {
	--theme-background-color: var(--color-pale-cornflower-blue);
}

[data-color-theme="salmon"] {
	--theme-background-color: var(--color-salmon);
}

[data-color-theme="orinoco"] {
	--theme-background-color: var(--color-orinoco);
}

[data-color-theme="periwinkle"] {
	--theme-background-color: var(--color-periwinkle);
}

[data-color-theme="olive-green"] {
	--theme-background-color: var(--color-olive-green);
}

[data-color-theme="neptune"] {
	--theme-background-color: var(--color-neptune);
}

[data-color-theme="lavender"] {
	--theme-background-color: var(--color-lavender);
}

[data-color-theme="tomato"] {
	--theme-background-color: var(--color-tomato);
}
