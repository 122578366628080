.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper {
		padding-right: var(--space-50--px);
		padding-left: var(--space-50--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.wrapper {
		margin: 0 auto;
		max-width: 90rem;
	}
}

/* from-xlarge,min-width-1680px */
@media (width >= 105rem) {
	.wrapper {
		padding-inline: 0;
	}
}

/* #region Narrow */
.wrapper[data-size="narrow"] {
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper[data-size="narrow"] {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 41.25rem;
	}
}
/* #endregion Narrow */

/* #region Medium */
.wrapper[data-size="medium"] {
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.wrapper[data-size="medium"] {
		margin: 0 auto;
		padding-inline: 0;
		max-width: 55.625rem;
	}
}
/* #endregion Medium */
