[data-animated-icon="beyond-boundaries"] {
	color: var(--color-black-warm);
	overflow: hidden;
}

[data-animated-icon="beyond-boundaries"] [data-shape~="a"] {
	fill: none;
	stroke: currentColor;
	stroke-width: 1px;
	transform-origin: center;
}

[data-animated-icon="beyond-boundaries"] [data-shape~="a"] {
	&:is([data-shape~="0"]) {
		transform: rotate(calc(0 * 45deg)) scale(1);
	}

	&:is([data-shape~="1"]) {
		transform: rotate(calc(1 * 45deg)) scale(0.7071067811865);
	}

	&:is([data-shape~="2"]) {
		transform: rotate(calc(2 * 45deg)) scale(0.5);
	}

	&:is([data-shape~="3"]) {
		transform: rotate(calc(3 * 45deg)) scale(0.3535533905933);
	}

	&:is([data-shape~="4"]) {
		transform: rotate(calc(4 * 45deg)) scale(0);
	}

	&:is([data-shape~="5"]) {
		transform: rotate(calc(5 * 45deg)) scale(0);
	}
}

[data-animated-icon="beyond-boundaries"] [data-shape] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
	transform-origin: center;
}

[data-hover] [data-animated-icon="beyond-boundaries"] [data-shape~="a"][data-shape~="1"] {
	animation-name: ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_1;
}

[data-hover] [data-animated-icon="beyond-boundaries"] [data-shape~="a"][data-shape~="2"] {
	animation-name: ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_2;
}

[data-hover] [data-animated-icon="beyond-boundaries"] [data-shape~="a"][data-shape~="3"] {
	animation-name: ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_3;
}

[data-hover] [data-animated-icon="beyond-boundaries"] [data-shape~="a"][data-shape~="4"] {
	animation-name: ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_4;
}

[data-hover] [data-animated-icon="beyond-boundaries"] [data-shape~="a"][data-shape~="5"] {
	animation-name: ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_5;
}

@keyframes ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_1 {
	from {
		transform: rotate(calc(1 * 45deg)) scale(0.7071067811865);
	}

	to {
		transform: rotate(calc(1 * 45deg)) scale(1.4142135623731);
	}
}

@keyframes ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_2 {
	from {
		transform: rotate(calc(2 * 45deg)) scale(0.5);
	}

	to {
		transform: rotate(calc(2 * 45deg)) scale(1);
	}
}

@keyframes ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_3 {
	from {
		transform: rotate(calc(3 * 45deg)) scale(0.3535533905933);
	}

	to {
		transform: rotate(calc(3 * 45deg)) scale(0.7071067811865);
	}
}

@keyframes ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_4 {
	from {
		transform: rotate(calc(4 * 45deg)) scale(0);
	}

	to {
		transform: rotate(calc(4 * 45deg)) scale(0.5);
	}
}

@keyframes ANIMATED_ICON__BEYOND_BOUNDARIES__SHAPE_A_5 {
	from,
	35% {
		transform: rotate(calc(5 * 45deg)) scale(0);
	}

	to {
		transform: rotate(calc(5 * 45deg)) scale(0.3535533905933);
	}
}
