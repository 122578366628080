.numbers {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.numbers {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.numbers__column[data-column-right] {
		grid-column: 2;
	}
}

.numbers__item {
	display: grid;
	grid-column-gap: var(--space-30--px);
	grid-template-columns: repeat(2, minmax(0, 50%));
}

/* min-width-560px and max-width-767px */
@media (35rem <= width < 48rem) {
	.numbers__item {
		grid-template-columns: minmax(0, 30%) minmax(0, 70%);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.numbers__item {
		grid-template-columns: minmax(0, 30%) minmax(0, 70%);
	}
}

.numbers__item__number {
	grid-row: 1;
	grid-column: 1;
}

.numbers__item__text {
	grid-column: 2;
}

.numbers__item:not(:last-child) {
	border-bottom: 1px solid var(--color-black-warm-20);
	margin-block-end: var(--space-30--rem);
	padding-block-end: var(--space-30--rem);
}
