.hero-home-v2 {
	background-color: var(--theme-background-color);
	padding-block-start: var(--space-10--rem);
	padding-block-end: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-home-v2 {
		padding-block-start: var(--space-20--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-home-v2 {
		padding-block-start: var(--space-30--rem);
	}
}

.hero-home-v2__content {
	aspect-ratio: 7 / 10;
	border-radius: calc(1px + var(--border-radius-5));
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: var(--space-5--rem);
	margin-block-start: var(--space-60--rem);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem) var(--space-60--rem);
	justify-content: center;
	position: relative;
}

[data-video-is-dark].hero-home-v2__content {
	background-color: var(--color-black-warm);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hero-home-v2__content {
		aspect-ratio: 16 / 9;
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem) var(--space-100--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-home-v2__content {
		gap: var(--space-10--rem);
		padding-inline: var(--space-60--px);
		padding-block: var(--space-60--rem) var(--space-150--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.hero-home-v2__content {
		padding-block-end: var(--space-200--rem);
	}
}

.hero-home-v2__title,
.hero-home-v2__subtitle {
	hyphens: auto;
	margin: 0;
	z-index: 1;
}

.hero-home-v2__title {
	color: var(--color-black-warm);
	text-align: center;
}

[data-video-is-dark] .hero-home-v2__title {
	color: var(--color-off-white);
}

.hero-home-v2__subtitle {
	color: var(--color-black-warm-70);
	text-align: center;
	text-wrap: pretty;
}

[data-video-is-dark] .hero-home-v2__subtitle {
	color: var(--color-off-white-70);
}

.hero-home-v2__video-container {
	inset: 0;
	position: absolute;
}

.hero-home-v2__video {
	border-radius: var(--border-radius-5);
	object-fit: cover;
	width: 100%;
	height: 100%;
}
