.business-services__columns {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-30--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.business-services__columns {
		flex-direction: row;
		column-gap: var(--space-30--px);
	}
}

.business-services__column {
	display: flex;
	flex-direction: column;
	gap: var(--space-30--px) 0;
	width: 100%;
}

.business-services__topic {
	background-color: var(--color-pale-cornflower-blue-20);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: var(--space-30--rem) 0;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.business-services__topic {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.business-services__topic:last-child {
	height: 100%;
}

.business-services__topic > h3 {
	margin: 0;
	padding: 0;
}

.business-services__topic__title {
	box-sizing: border-box;
	display: inline-block;
	hyphens: auto;
	padding-inline-end: var(--space-30--px);
	position: relative;
	text-decoration-color: transparent;
	width: 100%;
}

.business-services__topic__title::after {
	background-image: url("../../images/arrow_forward.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1rem;
	content: "";
	position: absolute;
	top: 0.313rem;
	right: 0;
	width: 20px;
	height: 20px;
}

.business-services__service-list {
	margin: 0;
	padding: 0;
}

.business-services__service-list .business-services__service-item[data-has-topic-title] {
	padding-block-end: var(--space-30--rem);
}

.business-services__service-list .business-services__service-item[data-has-topic-title]:last-child {
	padding-block-end: 0;
}

.business-services__service-item {
	border-top: 1px solid var(--color-black-warm-20);
	list-style-type: none;
	padding-block: var(--space-30--rem);
}

.business-services__service-item:last-child {
	padding-block-end: 0;
}

.business-services__service-item[data-has-topic-title] {
	border-top: none;
	padding: 0;
}

.business-services__service-item[data-has-topic-title] + .business-services__service-item[data-has-topic-title] {
	padding-block-start: var(--space-30--rem);
}

.business-services__service-item__title {
	box-sizing: border-box;
	display: inline-block;
	margin-block-end: var(--space-30--rem);
	padding-inline-end: var(--space-50--px);
	position: relative;
	text-decoration-color: rgba(0, 0, 0, 0);
	width: 100%;
}

.business-services__service-item__title::after {
	background-image: url("../../images/arrow_forward.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 0.8rem;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 20px;
	height: 20px;
}

.business-services__sub-service-list {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-5--px);
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.business-services__sub-service-list {
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: var(--space-5--rem);
	}
}

.business-services__sub-service-item {
	background-color: var(--color-pale-cornflower-blue-80);
	border-radius: var(--border-radius-5);
	display: inline-block;
	transition: background-color 256ms linear;
}

.business-services__sub-service-item:hover {
	background-color: var(--color-black-warm);
	color: var(--color-off-white);
}

.business-services__sub-service-item > a {
	display: inline-block;
	hyphens: auto;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	text-decoration: none;
}
