[data-animated-icon="connect"] {
	color: var(--color-black-warm);
}

[data-animated-icon="connect"] [data-shape="a"],
[data-animated-icon="connect"] [data-shape="c"] {
	fill: none;
	stroke: currentColor;
	stroke-miterlimit: 10;
	stroke-width: 20px;
}

[data-animated-icon="connect"] [data-shape="b"],
[data-animated-icon="connect"] [data-shape="d"] {
	fill: currentColor;
}

[data-animated-icon="connect"] [data-group],
[data-animated-icon="connect"] [data-shape] {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.3, 0, 0.7, 1);
}

[data-animated-icon="connect"] [data-group="a"] {
	transform-origin: center;
}

[data-hover] [data-animated-icon="connect"] [data-group="a"] {
	animation-name: ANIMATED_ICON__CONNECT__GROUP_A;
}

[data-hover] [data-animated-icon="connect"] [data-shape="a"] {
	animation-name: ANIMATED_ICON__CONNECT__SHAPE_A;
}

[data-hover] [data-animated-icon="connect"] [data-shape="b"] {
	animation-name: ANIMATED_ICON__CONNECT__SHAPE_B;
}

[data-hover] [data-animated-icon="connect"] [data-shape="c"] {
	animation-name: ANIMATED_ICON__CONNECT__SHAPE_C;
}

[data-hover] [data-animated-icon="connect"] [data-shape="d"] {
	animation-name: ANIMATED_ICON__CONNECT__SHAPE_D;
}

@keyframes ANIMATED_ICON__CONNECT__GROUP_A {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(180deg);
	}
}

@keyframes ANIMATED_ICON__CONNECT__SHAPE_A {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc((1080px / 2) - 360px), calc((1080px / 2) - 360px));
	}
}

@keyframes ANIMATED_ICON__CONNECT__SHAPE_B {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc(-1 * ((1080px / 2) - 360px)), calc((1080px / 2) - 360px));
	}
}

@keyframes ANIMATED_ICON__CONNECT__SHAPE_C {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc(-1 * ((1080px / 2) - 360px)), calc(-1 * ((1080px / 2) - 360px)));
	}
}

@keyframes ANIMATED_ICON__CONNECT__SHAPE_D {
	from,
	to {
		transform: translate(0, 0);
	}

	47.5%,
	52.5% {
		transform: translate(calc((1080px / 2) - 360px), calc(-1 * ((1080px / 2) - 360px)));
	}
}
