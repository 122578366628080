:root {
	/* #region Colors */
	--color-foreground: rgb(35, 31, 32);
	--color-background: rgb(253, 245, 229);
	--color-off-white: rgb(253, 245, 229);
	--color-off-white-10: rgba(253, 245, 229, 0.1);
	--color-off-white-30: rgba(253, 245, 229, 0.3);
	--color-off-white-70: rgba(253, 245, 229, 0.7);
	--color-black-warm: rgb(35, 31, 32);
	--color-black-warm-5: rgba(35, 31, 32, 0.05);
	--color-black-warm-10: rgba(35, 31, 32, 0.1);
	--color-black-warm-20: rgba(35, 31, 32, 0.2);
	--color-black-warm-50: rgba(35, 31, 32, 0.5);
	--color-black-warm-70: rgba(35, 31, 32, 0.7);
	--color-sidecar: rgb(235, 223, 171);
	--color-quicksand: rgb(198, 150, 133);
	--color-chablis: rgb(253, 230, 232);
	--color-kournikova: rgb(249, 216, 75);
	--color-texas-rose: rgb(254, 166, 84);
	--color-swans-down: rgb(212, 225, 223);
	--color-gainsboro: rgb(226, 226, 226);
	--color-heather: rgb(173, 179, 194);
	--color-onahou: rgb(191, 230, 238);
	--color-pale-cornflower-blue: rgb(177, 209, 239);
	--color-pale-cornflower-blue-20: rgba(177, 209, 239, 0.2);
	--color-pale-cornflower-blue-80: rgba(177, 209, 239, 0.8);
	--color-salmon: rgb(244, 128, 106);
	--color-orinoco: rgb(203, 206, 164);
	--color-periwinkle: rgb(201, 200, 232);
	--color-olive-green: rgb(170, 187, 104);
	--color-neptune: rgb(127, 173, 180);
	--color-lavender: rgb(174, 171, 252);
	--color-tomato: rgb(249, 108, 71);
	--color-warning: rgb(242 149 0); /* Orange - contrast 4.5 & 7 */
	--color-error: rgb(207 0 0); /* Red - contrast 4.5 & 7 */
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "TWK Everett", "TWK Everett Fallback", sans-serif;
	--font-body-sans: "Inter", "Inter Fallback", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-2--px: 2px;
	--space-2--rem: 0.125rem;
	--space-5--px: 5px;
	--space-5--rem: 0.3125rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-48--px: 48px;
	--space-48--rem: 3rem;
	--space-50--px: 50px;
	--space-50--rem: 3.125rem;
	--space-60--px: 60px;
	--space-60--rem: 3.75rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	--space-150--px: 150px;
	--space-150--rem: 9.375rem;
	--space-200--px: 200px;
	--space-200--rem: 12.5rem;
	/* #endregion Spacers */

	/* #region Borders */
	--border-radius-5: 5px;
	--border-radius-9999: 9999px;
	/* #endregion Borders */
}
