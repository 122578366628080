.highlight-cards-icon {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-icon {
		padding-inline: var(--space-60--px);
	}
}

.highlight-cards-icon__header {
	text-align: center;
	text-wrap: balance;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-icon__header {
		margin: 0 auto;
		max-width: 26.75rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.highlight-cards-icon__header {
		max-width: 31.5rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.highlight-cards-icon__header {
		max-width: 41.25rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.highlight-cards-icon__header {
		max-width: 51.25rem;
	}
}

.highlight-cards-icon__title,
.highlight-cards-icon__subtitle {
	margin: 0;
}

.highlight-cards-icon__subtitle {
	color: var(--color-black-warm-70);
}

.highlight-cards-icon__list {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style: none;
	margin: 0;
	margin-block-start: var(--space-60--rem);
	padding: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.highlight-cards-icon__list {
		grid-column-gap: var(--space-30--rem);
		grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
	}
}

.highlight-cards-icon__list__item {
	background-color: var(--color-background);
	border-radius: var(--border-radius-5);
	counter-increment: cards;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-icon__list__item {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.highlight-cards-icon__list__item mr-hover-animation {
	display: block;
}

.highlight-cards-icon__list__item__icon {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	display: block;
	margin-block-end: var(--space-60--rem);
	padding-inline: 10px;
	padding-block: 0.625rem;
	width: 50px;
	height: 50px;
}

.highlight-cards-icon__list__item__title {
	text-wrap: balance;
}
