.awards {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.awards {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.awards {
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}

.awards__item {
	border-bottom: 1px solid var(--color-black-warm-20);
	display: flex;
	flex-direction: column;
	row-gap: var(--space-30--rem);
	padding-bottom: var(--space-30--rem);
}

.awards__item__title,
.awards__item__year {
	margin: 0;
}

.awards__item__year {
	font-weight: 600;
}

.awards__item__media {
	margin-block-start: auto;
	width: 12.5rem;
	height: 3.375rem;
}

.awards__item__image {
	width: auto;
	height: 100%;
}
