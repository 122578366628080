.site-header {
	border-bottom: 1px solid var(--color-black-warm-20);
	display: flex;
	padding-block-end: var(--space-10--rem);
	justify-content: space-between;
	align-items: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header {
		padding-block-end: var(--space-20--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header {
		border-bottom: none;
		display: grid;
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
		align-items: flex-start;
	}
}

.site-header__logo .icon {
	display: block;
	fill: var(--color-black-warm);
	width: 10.75rem;
	height: 1.25rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__logo {
		margin-block-start: var(--space-10--rem);
	}
}

/* #region Hamburger */
/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header mr-input-sink {
		display: none;
	}
}

.site-header__hamburger {
	-mrh-resets: button;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	padding-inline: 12px;
	padding-block: 0.875rem;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
}

.site-header__hamburger__line {
	background-color: var(--color-black-warm);
	display: block;
	transition: transform 176ms ease-in-out;
	width: 0.9375rem;
	height: 0.125rem;
}

body.is-showing-modal-dialog .site-header__hamburger__line:first-child {
	transform: rotate(-45deg) translate(-1.5px, 2px);
	transform-origin: center;
}

body.is-showing-modal-dialog .site-header__hamburger__line:last-child {
	transform: rotate(45deg) translate(-2px, -2px);
	transform-origin: center;
}
/* #endregion Hamburger */

/* #region Navigation */
/* up-to-medium,max-width-1023px */
@media (width < 64rem) {
	.site-header__navigation {
		display: none;
	}
}

.site-header__navigation .primary-navigation {
	display: grid;
	grid-column-gap: var(--space-30--px);
	grid-auto-flow: column;
	grid-template-rows: repeat(2, auto);
	grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
}
/* #endregion Navigation */
