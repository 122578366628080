.image-50-50 {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50 {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50[data-reverse-columns] [data-column-a] {
		display: flex;
		grid-column: 2;
		justify-content: flex-end;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50[data-reverse-columns] [data-column-b] {
		grid-column: 1;
	}
}

.image-50-50__item {
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50__item[data-column-a] .media {
		grid-row: 1;
		grid-column: 1;
		width: calc(100% - 100px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.image-50-50__item[data-column-a] .media {
		width: calc(100% - 150px);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.image-50-50__item[data-column-a] .media {
		width: calc(100% - 200px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50__item[data-column-b] {
		grid-row: 1;
		grid-column: 2;
	}
}
