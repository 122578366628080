.article-list-related {
}

.article-list-related__title {
	margin-block-end: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.article-list-related__title {
		width: calc(50% - var(--space-15--px));
	}
}
