.highlight-cards-number {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-60--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-number {
		padding-inline: var(--space-60--px);
	}
}

.highlight-cards-number__header {
	text-align: center;
	text-wrap: balance;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-number__header {
		margin: 0 auto;
		max-width: 26.75rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.highlight-cards-number__header {
		max-width: 32.75rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.highlight-cards-number__header {
		max-width: 41.25rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.highlight-cards-number__header {
		max-width: 51.25rem;
	}
}

.highlight-cards-number__title,
.highlight-cards-number__subtitle {
	margin: 0;
}

.highlight-cards-number__subtitle {
	color: var(--color-black-warm-70);
}

.highlight-cards-number__list {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	list-style: none;
	margin: 0;
	margin-block-start: var(--space-60--rem);
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.highlight-cards-number__list {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.highlight-cards-number__list {
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}

.highlight-cards-number__list__item {
	background-color: var(--color-background);
	border-radius: var(--border-radius-5);
	counter-increment: cards;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.highlight-cards-number__list__item {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

.highlight-cards-number__list__item::before {
	border: 2px solid var(--color-black-warm);
	box-sizing: border-box;
	content: counter(cards, decimal-leading-zero);
	display: flex;
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-weight: 500;
	line-height: 1.5;
	margin-block-end: var(--space-30--rem);
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	width: 2.8125rem;
	height: 2.8125rem;
}

.highlight-cards-number__list__item:nth-child(odd)::before {
	border-radius: var(--border-radius-9999);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.highlight-cards-number__list__item::before {
		font-size: 0.875rem;
	}
}

.highlight-cards-number__list__item__title {
	margin-block-end: var(--space-10--rem);
	text-wrap: balance;
}
