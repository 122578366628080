.image-50-50 {
	display: grid;
	grid-row-gap: var(--space-30--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50 {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50[data-only-image-b] .media {
		grid-column: 2;
	}
}
