* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-body-sans);
	font-size: 1.0625rem;
	font-weight: 400;
	line-height: 1.5;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-wrap: balance;
}

h1 {
	margin-block: var(--space-60--rem);
}

h2,
h3,
h4,
h5,
h6 {
	margin-block-start: var(--space-60--rem);
	margin-block-end: var(--space-30--rem);
}

p {
	margin-block: var(--space-30--rem);
}

:is(h1, h2, h3, h4, h5, h6, p):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.type-a,
h1 {
	font-family: var(--font-sans);
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-a,
	h1 {
		font-size: 2.25rem;
	}
}

.type-b,
h2 {
	font-family: var(--font-sans);
	font-size: 1.625rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-b,
	h2 {
		font-size: 2rem;
	}
}

.type-c,
h3 {
	font-family: var(--font-sans);
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-c,
	h3 {
		font-size: 1.625rem;
	}
}

.type-d,
h4 {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-d,
	h4 {
		font-size: 1.375rem;
	}
}

.type-e,
h5 {
	font-family: var(--font-sans);
	font-size: 0.8125rem;
	font-weight: 500;
	line-height: 1.5;
	text-transform: uppercase;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-e,
	h5 {
		font-size: 0.875rem;
	}
}

.type-f,
h6 {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.5;
	text-transform: uppercase;
}

.type-g,
blockquote {
	font-family: var(--font-body-sans);
	font-size: 1.0625rem;
	font-weight: 400;
	line-height: 1.5;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-g,
	blockquote {
		font-size: 1.125rem;
	}
}

.type-h {
	font-family: var(--font-body-sans);
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.5;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-h {
		font-size: 1rem;
	}
}

.type-i {
	font-family: var(--font-sans);
	font-size: 2.25rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-i {
		font-size: 3.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-i {
		font-size: 3.75rem;
	}
}

.type-j {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-weight: 400;
	line-height: 1.3;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-j {
		font-size: 2.375rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-j {
		font-size: 2.75rem;
	}
}

.type-k {
	font-family: var(--font-body-sans);
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 1.5;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.type-k {
		font-size: 1.5rem;
	}
}

i,
em {
	font-style: italic;
}

blockquote:not([class]) {
	border-left: 2px solid var(--color-black-warm-20);
	margin-inline: 0;
	margin-block: var(--space-60--rem);
	padding-inline-start: var(--space-30--px);
	position: relative;
}

blockquote > p,
blockquote > cite,
blockquote > p > cite {
	font-style: normal;
	margin: 0;
}

blockquote:not([class]) > p:not(:last-child) {
	margin-block-end: var(--space-30--rem);
}

b,
strong {
	font-weight: 600;
}
