.cta-banner {
	background-color: transparent;
	border: 1px solid var(--color-black-warm-20);
	border-radius: var(--border-radius-5);
	color: var(--color-black-warm);
	display: block;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	text-decoration: none;
	transition:
		background-color 256ms linear,
		border-color 256ms linear;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.cta-banner {
		display: flex;
		column-gap: var(--space-30--px);
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
		justify-content: space-between;
		align-items: center;
	}
}

.cta-banner:hover {
	background-color: var(--theme-cta-banner-hover-color);
	border-color: var(--theme-cta-banner-hover-color);
}

.cta-banner__icon {
	background-color: var(--color-black-warm-5);
	border-radius: var(--border-radius-5);
	box-sizing: border-box;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	transition: background-color 256ms linear;
	width: 3.375rem;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.cta-banner__icon {
		margin-block-start: var(--space-30--rem);
	}
}

.cta-banner:hover .cta-banner__icon {
	background-color: var(--color-black-warm);
}

.cta-banner__icon::after {
	background-color: var(--color-black-warm);
	content: "";
	display: block;
	mask-image: url("../../images/arrow_outward.svg");
	transition: background-color 256ms linear;
	width: 1.5rem;
	height: 1.5rem;
}

.cta-banner:hover .cta-banner__icon::after {
	background-color: var(--color-off-white);
}
