.hero-detail {
	background-color: var(--theme-background-color);
	padding-block-start: var(--space-10--rem);
	padding-block-end: var(--space-60--rem);
	text-align: center;
}

.hero-detail__navigation {
	text-align: left;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail {
		padding-block-start: var(--space-20--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail {
		padding-block-start: var(--space-30--rem);
	}
}

.hero-detail__title {
	margin-block-end: var(--space-30--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail__title {
		margin-inline: var(--space-100--px);
		margin-block-start: var(--space-80--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail__title {
		margin-block-start: var(--space-100--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.hero-detail__title {
		margin-inline: var(--space-200--px);
	}
}

.hero-detail__media {
	display: flex;
	margin-block: var(--space-30--rem);
	justify-content: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail__media {
		margin-block: var(--space-60--rem);
	}
}

/* #region Image */
/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hero-detail__media[data-media-orientation="landscape"] img {
		width: 75%;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hero-detail__media[data-media-orientation="portrait"] img {
		width: 50%;
	}
}
/* #endregion Image */

/* #region Info */
.hero-detail__info {
	display: grid;
	grid-row-gap: var(--space-30--rem);
	margin-block-start: var(--space-30--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail__info {
		margin-inline: var(--space-100--px);
		margin-block-start: var(--space-60--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail__info {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.hero-detail__info {
		margin-inline: var(--space-200--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail__info__written-by {
		grid-column: 1;
		text-align: left;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail__info__meta {
		grid-column: 2;
		text-align: right;
	}
}

.hero-detail__info__written-by p {
	margin: 0;
}

.hero-detail__info__written-by__title {
	color: var(--color-black-warm-70);
}

.hero-detail__info__meta__terms {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.3125rem;
	column-gap: 5px;
	justify-content: center;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail__info__meta__terms {
		justify-content: flex-end;
	}
}

.hero-detail__info__meta__terms p {
	margin: 0;
}

.hero-detail__info__meta__reading-time {
	margin: 0;
}
/* #endregion Info */
