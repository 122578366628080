.reviews {
	display: grid;
	margin: 0;
	padding: 0;
	height: max-content;
}

.reviews__title {
	margin: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.reviews__title {
		width: calc(50% - var(--space-15--px));
	}
}

.reviews__item {
	grid-row: 1;
	grid-column: 1;
	opacity: 0;
	transition: opacity 256ms ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 0;
}

.reviews__item[data-slide="current"] {
	opacity: 1;
	visibility: visible;
	z-index: 1;
}

.reviews__item[data-slide="next"],
.reviews__item[data-slide="previous"] {
	pointer-events: none;
	z-index: 2;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.reviews__item {
		display: grid;
		grid-column-gap: var(--space-10--px);
		grid-template-areas: "image content";
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.reviews__item {
		grid-column-gap: var(--space-30--px);
	}
}

.reviews__item__content {
	background-color: var(--color-onahou);
	border-radius: var(--border-radius-5);
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.reviews__item__content {
		display: flex;
		flex-direction: column;
		grid-area: content;
		padding-block: var(--space-60--rem);
		justify-content: space-between;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.reviews__item__content {
		padding-inline: var(--space-60--rem);
	}
}

.reviews__item__content__title {
	margin-block-end: var(--space-60--rem);
}

.reviews__item__content__inner {
	display: flex;
	flex-direction: column;
	gap: var(--space-20--rem);
}

.reviews__item__content__inner__author,
.reviews__item__content__inner__topic {
	margin: 0;
}

.reviews__item__content__inner__function {
	color: var(--color-black-warm-70);
	margin: 0;
}

/* #region Image */
.reviews__image {
	border-radius: var(--border-radius-5);
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.reviews__image {
		display: none;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.reviews__media {
		grid-area: image;
		position: relative;
	}
}
/* #endregion Image */

.reviews-container {
	display: grid;
	grid-row-gap: var(--space-60--rem);
}

.reviews-controls {
	border-radius: var(--border-radius-5);
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

/* #region Controls Arrows */
.reviews-controls__arrows {
	display: flex;
	grid-row: 2;
	grid-column: 2;
	align-self: start;
	justify-self: end;
	z-index: 20;
}

.reviews-controls__arrow {
	-mrh-resets: button;
	border: 1px solid var(--color-black-warm-20);
	border-radius: var(--border-radius-5);
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		background-color 256ms ease-in,
		color 256ms ease-in;
	width: calc((44px + 2.75rem + 2.75rem) / 3);
	height: calc((44px + 2.75rem + 2.75rem) / 3);
}

.reviews-controls__arrow:hover {
	background-color: var(--color-black-warm-5);
	color: var(--color-black-warm);
}

.reviews-controls__arrow[data-carousel-controls="previous"]::before,
.reviews-controls__arrow[data-carousel-controls="next"]::after {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 0.9375rem;
	content: "";
	width: 100%;
	height: 100%;
}

.reviews-controls__arrow[data-carousel-controls="previous"]::before {
	background-image: url("../../images/arrow_backward.svg");
}

.reviews-controls__arrow[data-carousel-controls="next"]::after {
	background-image: url("../../images/arrow_forward.svg");
}
/* #endregion Controls Arrows */
