.site-footer {
	background-color: var(--color-foreground);
	color: var(--color-background);
	padding-block: var(--space-60--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer {
		padding-block-start: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer {
		padding-block-start: var(--space-100--rem);
	}
}

.site-footer__title {
	margin-block-end: var(--space-60--rem);
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__title {
		margin-inline: var(--space-60--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__title {
		margin-inline: var(--space-200--px);
		margin-block-end: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__title {
		margin-block-end: var(--space-100--rem);
	}
}

/* #region Numbers */
.site-footer__numbers {
	grid-template-areas: "one" "two" "three" "four";
	margin-block: var(--space-60--rem);
}

/* min-width-375px */
@media (width >= 23.4375rem) {
	.site-footer__numbers {
		display: grid;
		grid-template-areas: "one two" "four three";
		grid-template-columns: repeat(2, 50%);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__numbers {
		grid-template-areas: "one two three four";
		grid-template-columns: repeat(4, 25%);
		margin-block: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__numbers {
		margin-block: var(--space-100--rem);
	}
}

.site-footer__numbers__item {
	aspect-ratio: 1 / 1;
	border: 1px solid var(--color-off-white-30);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	opacity: 0;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: opacity 1024ms ease-in-out;
	width: 100%;
	height: 100%;
}

html.no-js .site-footer__numbers__item {
	opacity: 1;
}

.site-footer__numbers__item[data-intersected-with-viewport] {
	opacity: 1;
}

.site-footer__numbers__item:nth-child(1) {
	grid-area: one;
}

.site-footer__numbers__item:nth-child(2) {
	grid-area: two;
}

.site-footer__numbers__item:nth-child(3) {
	grid-area: three;
}

.site-footer__numbers__item:nth-child(4) {
	grid-area: four;
}

/* min-width-375px and up-to-medium,max-width-1023px */
@media (width >= 23.4375rem) and (width < 64rem) {
	.site-footer__numbers__item:nth-child(2) {
		margin-top: 0.03125rem;
		margin-left: -0.5px;
	}
}

/* min-width-375px and up-to-medium,max-width-1023px */
@media (width >= 23.4375rem) and (width < 64rem) {
	.site-footer__numbers__item:nth-child(4) {
		margin-top: -0.03125rem;
		margin-left: 0.5px;
	}
}

.site-footer__numbers__item__title,
.site-footer__numbers__item__subtitle {
	margin: 0;
}

.site-footer__numbers__item__subtitle {
	color: var(--color-off-white-70);
}

.site-footer__numbers__item:nth-child(odd) {
	border-radius: var(--border-radius-9999);
}
/* #endregion Numbers */

/* #region CTA */
.site-footer__cta {
	display: flex;
	flex-direction: column;
	margin-block: var(--space-60--rem);
	align-items: center;
	text-align: center;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__cta {
		margin-block: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__cta {
		margin-block: var(--space-100--rem);
	}
}

.site-footer__cta__title {
	color: var(--color-off-white-70);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__cta__title {
		margin-inline: var(--space-60--px);
		max-width: 34.25rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__cta__title {
		margin-inline: var(--space-200--px);
		max-width: 32.75rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.site-footer__cta__title {
		max-width: 48.75rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__cta__title {
		max-width: 58.75rem;
	}
}

.site-footer__cta__link {
	-mrh-resets: button;
	background-color: var(--color-off-white-10);
	border-radius: var(--border-radius-5);
	color: var(--color-off-white);
	font-family: var(--font-body-sans);
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.5;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	transition: background-color 256ms ease-in-out;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__cta__link {
		font-size: 1rem;
	}
}

.site-footer__cta__link:is(:hover, :focus) {
	background-color: var(--color-off-white-30);
}
/* #endregion CTA */

/* #region Navigation */
.site-footer__navigation {
	border-top: 1px solid var(--color-off-white-30);
	display: grid;
	grid-row-gap: var(--space-60--rem);
	padding-block: var(--space-60--rem);
}

/* min-width-375px */
@media (width >= 23.4375rem) {
	.site-footer__navigation {
		grid-row-gap: var(--space-60--rem);
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__navigation {
		grid-column-gap: var(--space-30--px);
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}

.site-footer__navigation__logo {
	background-image: url("../../../images/wn-emblem.svg");
	background-repeat: no-repeat;
	content: "";
	width: 6.25rem;
	height: 3.125rem;
}

.site-footer__navigation__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__navigation__list__item__link {
	color: var(--color-off-white-70);
	text-decoration: underline;
	text-decoration-color: transparent;
	transition:
		color 256ms ease-in-out,
		text-decoration-color 256ms ease-in-out;
}

.site-footer__navigation__list__item__link:hover,
.site-footer__navigation__list__item__link:active,
.site-footer__navigation__list__item[data-is-current] .site-footer__navigation__list__item__link {
	color: var(--color-off-white);
	text-decoration-color: var(--color-off-white);
}
/* #endregion Navigation */

/* #region Legal */
.site-footer__legal {
	border-top: 1px solid var(--color-off-white-30);
	display: grid;
	grid-row-gap: var(--space-30--rem);
	grid-template-areas:
		"copyright"
		"legal"
		"socials";
	padding-block-start: var(--space-60--rem);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-footer__legal {
		grid-column-gap: var(--space-30--px);
		grid-template-areas:
			"copyright ."
			"legal socials";
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__legal {
		grid-template-areas: "copyright legal . socials";
		grid-template-columns: repeat(4, minmax(0, 25%));
		align-items: center;
	}
}

.site-footer__legal__copyright {
	grid-area: copyright;
	margin-block-end: var(--space-30--rem);
	text-transform: uppercase;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__legal__copyright {
		margin-block-end: 0;
	}
}

.site-footer__legal__navigation {
	display: flex;
	column-gap: var(--space-15--px);
	grid-area: legal;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__legal__navigation__item__link {
	color: var(--color-off-white-70);
	text-decoration: underline;
	text-decoration-color: transparent;
	transition:
		color 256ms ease-in-out,
		text-decoration-color 256ms ease-in-out;
}

.site-footer__legal__navigation__item__link:hover,
.site-footer__legal__navigation__item[data-is-current] .site-footer__legal__navigation__item__link {
	color: var(--color-off-white);
	text-decoration-color: var(--color-off-white);
}

.site-footer__legal__socials {
	display: flex;
	column-gap: var(--space-15--px);
	grid-area: socials;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-footer__legal__socials__item__link {
	background-color: var(--color-off-white-70);
	display: block;
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	transition: background-color 256ms ease-in-out;
	width: 1.5rem;
	height: 1.5rem;
}

.site-footer__legal__socials__item__link:hover {
	background-color: var(--color-off-white);
}

.site-footer__legal__socials__item__link {
	mask-image: url("../../../images/external-link.svg");
}

.site-footer__legal__socials__item__link[href*="facebook.com"] {
	mask-image: url("../../../images/facebook.svg");
}

.site-footer__legal__socials__item__link[href*="instagram.com"] {
	mask-image: url("../../../images/instagram.svg");
}

.site-footer__legal__socials__item__link[href*="soundcloud.com"] {
	mask-image: url("../../../images/soundcloud.svg");
}

.site-footer__legal__socials__item__link[href*="x.com"] {
	mask-image: url("../../../images/x.svg");
}

.site-footer__legal__socials__item__link[href*="tiktok.com"] {
	mask-image: url("../../../images/tiktok.svg");
}

.site-footer__legal__socials__item__link[href*="linkedin.com"] {
	mask-image: url("../../../images/linkedin.svg");
}

.site-footer__legal__socials__item__link[href*="youtube.com"] {
	mask-image: url("../../../images/youtube.svg");
}

.site-footer__legal__socials__item__link[href*="vimeo.com"] {
	mask-image: url("../../../images/vimeo.svg");
}
/* #endregion Legal */
